<template>
	<div :class="'bpb-etiqueta'+tipo_etiqueta">
		<span class="bpb-etiqueta-text">{{ text }}</span>
		<span class="bpb-etiqueta-circle"></span>
		<span v-if="close" class="bpb-etiqueta-trash" @click="cerrar">
			<i class="gg-close"></i>
		</span>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			text: null 
			,value: null
			,type: null
			,close: false
		}
		,model: {
			prop: 'value'
			,event: 'close'
		}
		,methods: {
			cerrar: function() {
				this.$emit('close',this.value);
			}
		}
		,computed: {
			tipo_etiqueta: function() {
				const types = ['primary','success','warning','danger','info','secondary','dark','light'];

				let tipo = types.indexOf(this.type) > -1 ? this.type : 'primary';
				this.$log.info('tipo',tipo);

				return ' bpb-etiqueta-type-'+tipo;
			}
		}
	}
</script>

<style lang="scss">
	.bpb-etiqueta {
		background-color: #E6E6E6;
		padding: 8px 12px;
		border-radius: 10px;
		margin-bottom: 5px;
		margin-right: 10px;
		display: inline-block;
		position: relative;

		.bpb-etiqueta-text {
			margin-right: 20px;
		}

		.bpb-etiqueta-circle {
			width: 20px;
			height: 20px;
			background-color: red;
			border-radius: 50%;
			position: absolute;
			top: 9px;
			left: calc(100% - 25px);

		}

		.bpb-etiqueta-trash {
			position: absolute;
			top: 8px;
			left: calc(100% - 26px);

			i {
				color: #fff;
				--ggs: 0.7;
				display: inline-block;
				cursor: pointer;
			}
		}
	}

	.bpb-etiqueta:last-child {
		margin-right: 0px;
	}

	.bpb-etiqueta-type-primary {
		background-color: #0781cd;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #035D8F;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-success {
		background-color: #55d775;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #429669;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-warning {
		background-color: #f99f43;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #A86C3C;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-danger {
		background-color: #ea5455;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #9B3A3D;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-info {
		background-color: #17a2b8;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #45757A;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-secondary {
		background-color: #6c757d;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #373B3E;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-dark {
		background-color: #343a40;

		.bpb-etiqueta-text {
			color: #fff;
		}

		.bpb-etiqueta-circle {
			background-color: #000000;

			i {
				color: #fff;
			}
		}
	}

	.bpb-etiqueta-type-light {
		background-color: #E6E6E6;

		.bpb-etiqueta-text {
			color: #000;
		}

		.bpb-etiqueta-circle {
			background-color: #B3B3B3;

			i {
				color: #000;
			}
		}
	}
</style>