<template>
    <Modal :options="{width: '35vw', type:'error'}">
      <div class="title h2 text-white">{{ titulo }}</div>

      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h5><i class="fa fa-exclamation-triangle mr-sm-1"></i> <b>¡Atención!</b></h5>
            El cambio del estatus a <b class="text-uppercase">{{estatus}}</b>  restringirá la edición de información del registro, se deshabilitarán formularios y acciones relacionados a él,
            al aprobar el cambio de estatus sólo se podrá editar el estatus del mismo en el futuro.
            <br/>¿Esta seguro de continuar?
          </div>
        </div>
      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="$emit('continuar')" ><i class='fa fa-save mr-sm-1'></i>Continuar</button></div>
        </div>
      </div>

    </Modal>

</template>

<script>

import Modal from "@/components/Modal.vue";


export default {
  name: 'ModalDesactivarRegistro',
  components: {Modal},
  props: {
    titulo: {
      type: String,
      required:false,
      default() {
        return "Cambio de estatus de registro";
      }
    },
    estatus:{
      type: String,
      required:true,
    }
  },
}
</script>

<style scoped>

</style>
