
<template>

  <div>
    <Modal :options="{width: '85vw'}">

      <div class="title h2 text-white">Formulario Crédito</div>

      <div class="body">

        <div class="text-right mb-5" v-if="credito_id && !solo_lectura">
          <button class="btn btn-sm btn-primary" @click="pre_reestructurar_credito"> <i class='fa fa-refresh mr-sm-1'></i>Reestrcturar crédito</button>
        </div>

        <div class="text-right mb-5" v-if="ver_boton_cargar_origen" >
          <button class="btn btn-sm btn-primary" @click="pre_cargar_origen()"> <i class='fa fa-external-link mr-sm-1'></i>Cargar desde origen externo</button>
        </div>

        <div v-if="solo_lectura" class="text-right mb-4">
          <h5><span class="badge badge-warning">Crédito {{estatus}}</span></h5>
        </div>

        <div class="alert alert-warning" role="alert" v-if="solo_lectura">
          <i class="fa fa-exclamation-triangle mr-sm-1"></i> <b>Información de solo lecura:</b>  El estatus crédito tiene acceso reestringido a la edición de información, se deshablitan formularios y acciones exceptuando el estatus del mismo.
        </div>

        <div v-if="!solo_lectura" class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan los iconos:
          <ul class="list-group border-0 mt-2">
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-external-link mx-sm-1"></i> indican que fueron cargados desde un origen externo, si edita los valores obtenidos ya no podrán recuperarse.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-refresh mx-sm-1"></i> hacen referencia a campos dinámicos configurados por el administrador y pueden cambiar en cualquier momento.</li>
            <li class="list-group-item border-0 py-0 bg-transparent"><i class="fa fa-check-circle mx-sm-1"></i> indica que el campo es obligatorio.</li>
          </ul>
        </div>

        <div class="row justify-content-center">
          <div class="col-sm-3 form-group">
            <label for="no_credito" class="form-label text-truncate d-block" title="No. de Crédito">
              <i class="fa fa-check-circle text-primary"></i>
              No. de Crédito
            </label>
            <div class="text-bold" v-if="solo_lectura"><b>{{credito.no_credito}}</b></div>
            <input v-else v-model="credito.no_credito" type="text" name="no_credito" id="no_credito" class="form-control">
          </div>

          <div class="col-sm-3 form-group">
            <label for="estatus_credito"  class="form-label text-truncate d-block" :title="estatus_credito.nombre">
              <i class="fa fa-check-circle text-primary"></i>
              {{estatus_credito.nombre}}
            </label>
            <select  class="form-control" name="estatus_credito" id="estatus_credito"  v-model="credito.estatus">
              <option :value="estatus.valor" v-for="estatus in estatus_credito.opciones">{{estatus.nombre}}</option>
            </select>
          </div>

          <div class="col-sm-3 form-group" v-for="campo in campos_dinamicos ">
            <label v-if="solo_lectura"  class="form-label text-truncate d-block" :title="campo.nombre">
              {{ campo.nombre }}
            </label>
            <div class="text-bold" v-if="solo_lectura">
              <i v-if="!credito[campo.clave]" class="text-danger">Valor no ingresado</i>
              <b v-else>{{credito[campo.clave]}}</b>
            </div>
            <template v-else>
              <campo-dinamico v-if="campo.catalogo_id"
                              :label="campo.nombre"
                              :clave="campo.clave"
                              :tipo="campo.tipo_dato"
                              :valor="credito[campo.clave]"
                              :opciones_select="campo.catalogo.opciones"
                              :select_clave_valor="true"
                              :select_opciones_label="'nombre'"
                              :select_opciones_valor="'valor'"
                              :required="campo.campo_requerido"
                              v-model="credito[campo.clave]"
              >
                <template v-slot:label>
                  <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                </template>
              </campo-dinamico>

              <campo-dinamico v-else :label="campo.nombre"
                              :clave="campo.clave"
                              :tipo="campo.tipo_dato"
                              :valor="credito[campo.clave]"
                              :opciones_select="campo.tipo_dato !== 'array' ?  [] : campo.valores"
                              :required="campo.campo_requerido"
                              v-model="credito[campo.clave]"
              >
                <template v-slot:label>
                  <button  v-if="campo.es_de_origen_externo" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El valor del campo se cargó desde un origen externo"><i class="fa fa-external-link"></i></button>
                </template>
              </campo-dinamico>
            </template>

          </div>
        </div>

        <div class="mt-3 text-center">
          <div class="mb-4">
            <div class="h5 d-inline mr-sm-4">Fondeadores ({{credito.fondeadores.length}})</div>
            <button class="btn btn-sm btn-success d-inline" @click="pre_agregar_fondeador" v-if="!solo_lectura"><i class='fa fa-plus mr-sm-1'></i>Agregar Fondeador</button>
          </div>

          <etiqueta  v-for="fondeador in credito.fondeadores" :type="'light'" :text="fondeador.nombre" :value="fondeador" :close="!solo_lectura" @close="pre_quitar_fondeador($event)"></etiqueta>
        </div>



        <div class="mt-3"  v-if="credito.id !== null">
          <hr>
          <div class="h4 text-center mt5">Garantías</div>
          <garantias-credito  :persona_id="persona_id" :credito_id="credito.id" :solo_lectura="solo_lectura"></garantias-credito>
        </div>

      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto" v-if="!solo_lectura"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar {{!credito.id ? '' : 'cambios'}}</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalReestructurarCredito" :options="{width: '30vw',type:'info'}" @close="modalReestructurarCredito = false">
      <div class="title">Reestructurar crédito </div>
      <div class="body">
        IMPORTANTE: Para reestructurar este credito  debe ingresar los datos que se piden a continuación.

        <div class="row justify-content-center mt-4">
          <div class="col-12 form-group">
            <label for="no_credito" class="form-label font-weight-bold">No. de Crédito</label>
            <input v-model="credito_reestructura.no_credito" type="text" name="no_credito" id="no_credito" class="form-control">
          </div>


          <div class="col-12 form-group">
            <label for="nombre" class="form-label font-weight-bold">Estatus Crédito</label>
            <select class="form-control" name="estatus_credito" id="estatus_credito"  v-model="credito_reestructura.estatus" >
              <option :value="estatus.nombre" v-for="estatus in estatus_credito.opciones">{{estatus.nombre}}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="reestructurar_credito"><i class="fa fa-check-square mr-sm-1"></i>Reestructurar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="modalReestructurarCredito = false"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>


    <Modal v-if="modalFondeador" :options="{width: '45vw',type:'info'}" >
      <div class="title">Agregar Fondeador</div>
      <div class="body">
        <div class="row justify-content-center mt-4">
          <div class="col-12 form-group">

            <label for="indentificador" class="form-label font-weight-bold">Fondeador</label>
            <select class="form-control" name="fondeador" id="fondeador" v-model="fondeador_seleccionado" :disabled="!fondeadores_select.length">
              <option :value="null" v-if="!fondeadores_select.length" selected>No hay fondeadores disponibles para agregar.</option>
              <option :value="fondeador" v-for="fondeador in fondeadores_select">{{fondeador.nombre}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="agregar_fondeador" v-if="fondeadores_select.length"><i class="fa fa-check-square mr-sm-1"></i>Agregar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_agregar_quitar_fondeador"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalQuitarFondeador" :options="{width: '30vw',type:'error'}">
      <div class="title">Quitar fondeador asociado al crédito </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminaras relación entre el crédito y el fondeador <b>{{fondeador_seleccionado.nombre}}</b> <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-primary" @click="quitar_fondeador"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
          <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_agregar_quitar_fondeador"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <!--MODAL DESACTIVAR REGISTRO-->
    <modal-desactivar-registro
        v-if="modalConfirmarCambioEstatusLectura"
        :estatus="estatus" titulo="Cambiar estatus del crédito a solo lectura"
        @continuar="confirmar_cambio_estatus_lectura(true)"
        @cancelar="confirmar_cambio_estatus_lectura(false)"
    ></modal-desactivar-registro>

    <!--COMPONENTES CARGAR ORIGEN EXTERNO -->
    <FormularioParamsOrigen v-if="form_parametros_cargar_origen_datos" :origen="configuracion_origen_datos" @enviar="form_origen_enviar($event)" @cancelar="cancelar_form_params_origen()"></FormularioParamsOrigen>
    <FormulatioSeleccionarOrigen v-if="form_seleccion_origen_de_datos" :response_origen="origen_datos_response" @cancelar="cancelar_form_seleccion_origen()" @seleccion="asignar_valores_origen($event)"></FormulatioSeleccionarOrigen>


  </div>





</template>

<script>


import Modal from "@/components/Modal.vue";
import CampoDinamico from "@/apps/garantias_app/components/CampoDinamico.vue";
import creditosApi from "@/apps/garantias_app/api/personas/creditos/creditos.api";
import FormularioGarantia from "@/apps/garantias_app/pages/clientes/creditos/garantias/FormularioGarantia.vue";
import GarantiasCredito from "@/apps/garantias_app/pages/clientes/creditos/garantias/GarantiasCredito.vue";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
import Etiqueta from "@/components/Etiqueta.vue";
import fondeadoresApi from "@/apps/garantias_app/api/fondeadores/fondeadores.api";
import origenDatosApi from "@/apps/garantias_app/api/personas/origen_datos.api";
import FormulatioSeleccionarOrigen from "@/apps/garantias_app/components/FormularioSeleccionOrigen.vue";
import FormularioParamsOrigen from "@/apps/garantias_app/components/FormularioParamsOrigen.vue";
import garantiasApi from "@/apps/garantias_app/api/personas/creditos/garantias.api";
import ModalDesactivarRegistro from "@/apps/garantias_app/components/ModalDesactivarRegistro.vue";
export default {
  name: "FormularioCredito"
  ,components: {
    ModalDesactivarRegistro,
    FormularioParamsOrigen,
    FormulatioSeleccionarOrigen,
    Etiqueta, GarantiasCredito, FormularioGarantia, CampoDinamico, Modal}
  ,props: {
    persona_id: null,
    credito_id: {
      type: String,
      required: false,
      default() {
        return null
      }
    }
  }
  ,data() {
    return {
      paises: ['México']
      ,estatus_credito: {nombre: 'Estatus crédito', opciones: []}
      ,credito: {
        id: null
        , no_credito: null
        , estatus: null
        , propiedades: []
        , fondeadores: []
      }
      ,credito_reestructura: {
        no_credito: null
        ,estatus: null
      }
      ,campos_dinamicos: []
      ,garantias: []
      ,formGarantia:false
      ,index_garantia_seleccionada: null
      ,openModalEliminarGarantia: false
      ,validacion_garantias: false
      ,modalReestructurarCredito: false
      ,modalFondeador: false
      ,modalQuitarFondeador: false
      ,fondeadores: []
      ,fondeadores_eliminados: []
      ,fondeador_seleccionado: null
      ,configuracion_origen_datos: {
        id: null,
        identificador: null,
        origen_asincrono: false,
        parametros_request:[],
        campos:[] //Las propiedades que estan configuradas para ser cargadas desde el origen
      },
      form_parametros_cargar_origen_datos: false,
      form_seleccion_origen_de_datos: false
      ,origen_datos_response:[]
      ,estatus_credito_db: null // contiene el valor del estatus del crédito en edición como lo retorna el api al obtener el fideicomiso
      ,modalConfirmarCambioEstatusLectura:false
      
    }
  }
  ,mounted() {
    this.cargar_data()
  }
  ,computed:{
    validaciones_credito(){
      let validaciones = {
        no_credito: !this.credito.no_credito ? 'required' : 'max:255',
        estatus_credito: !this.credito.estatus ? 'required' : '',
      }

      for (const campo of this.campos_dinamicos) {
        const vacio = !this.credito[campo.clave] || this.credito[campo.clave] == null;
        validaciones[campo.clave] =  vacio && campo.campo_requerido ? 'required' : (campo.tipo_dato === 'string' ? 'max:255' : '')
      }

      return validaciones;
    }
    ,estatus(){
      return this.credito.estatus ? this.credito.estatus.toLowerCase() : ''
    }

    , solo_lectura(){
//       if (this.credito.hasOwnProperty('solo_lectura'))
//         return  this.credito.solo_lectura

      return  this.estatus_solo_lectura(this.estatus)
    }

    ,cambio_estatus_a_inactivo(){
      return !this.estatus_solo_lectura(this.estatus_fideicomiso_db) && this.estatus_solo_lectura(this.estatus)
    }

    ,fondeadores_select(){
      return this.fondeadores.filter((item_list) => {
          return !this.credito.fondeadores.some(fondeador => fondeador.id === item_list.id)
      })
    }
    ,ver_boton_cargar_origen(){
      return !this.credito.id && this.configuracion_origen_datos.origen_asincrono
    }
  }
  ,methods:{

    estatus_solo_lectura(estatus){
      estatus = !estatus ? estatus : estatus.toLowerCase()
      return (estatus === 'reestructurado' || estatus === 'inactivo')
    },

    cancelar_agregar_quitar_fondeador(){
      this.modalFondeador = false
      this.modalQuitarFondeador = false
      this.fondeador_seleccionado = null
    },

    async pre_agregar_fondeador(){
      const options = {
        columns: 'id,nombre',
        filters: [{
          relacion: "and",
          columna: "estatus",
          operador: "not_equal_to",
          valor1: 'inactivo',
          valor2: null
        }]
      }
      const request = await fondeadoresApi.index(false, options)
      this.fondeadores = request.data
      this.modalFondeador = true

    },

    async pre_quitar_fondeador(fondeador){
     this.fondeador_seleccionado = fondeador
     this.modalQuitarFondeador = true
    },

    async quitar_fondeador(){
      this.modalQuitarFondeador = false
      const index_fondeador_credito = this.credito.fondeadores.findIndex(item => {
        return item.id === this.fondeador_seleccionado.id
      });

      //this.fondeadores.splice(index_fondeador_select,1)
      this.credito.fondeadores.splice(index_fondeador_credito,1)
      this.fondeador_seleccionado = null
    },

    async agregar_fondeador(){
      this.modalFondeador = false
     this.credito.fondeadores.push(this.fondeador_seleccionado)
      this.fondeador_seleccionado = null

    },
    async pre_reestructurar_credito(){
      let isValid = await this.$validator(this.validaciones_credito)
      if (isValid) {
        this.modalReestructurarCredito = true
        this.credito_reestructura = {
          no_credito: null
          , estatus: null
        }
      }
    },
    async reestructurar_credito(){
      let isValid = await this.$validator({
        no_credito: !this.credito_reestructura.no_credito ? 'required' : 'max:255',
        estatus: !this.credito_reestructura.estatus ? 'required' : '',
      })

      if (isValid) {

        try {
          let request = await creditosApi.reestructurar(this.persona_id,this.credito.id, this.credito_reestructura)
          this.credito = {no_credito: request.data.external_id, ...request.data}

          this.$helper.showMessage('Reestructurar credito', 'Información del credito guardada exitosamente.', 'success', 'alert')
          await this.cargarCampos()
          this.modalReestructurarCredito = false
          this.$emit('actualizar', this.credito)

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage('Reestructurar credito', 'Ocurrio un error al guardar datos del credito, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    },

    async cargarCatalogoEstatus(){
      const dataSource = await catalogosApi.buscar('estatus_credito')
      this.estatus_credito = dataSource.data

    },

    async cargar_data(){

      await this.cargarCatalogoEstatus()

      if (this.credito_id !== null) {
        this.credito.id = this.credito_id
        const dataSource = await creditosApi.obtener(this.persona_id, this.credito.id)
        this.credito = {no_credito: dataSource.data.external_id, ...dataSource.data}
        this.estatus_credito_db = this.credito.estatus;
        await this.cargarCampos()
      }else{
        this.validar_origen()
      }

    },

    cancelar(){
      this.$emit('cancelar')
    }
    ,async guardar() {
      let isValid = await this.$validator(this.validaciones_credito)

      let payload = {...this.credito}

      if (payload.fondeadores && !payload.fondeadores.length)
        delete payload.fondeadores
      else
        payload.fondeadores = payload.fondeadores.map( item => item.id);

      if (isValid) {

        try {
          if (this.credito.id !== null) {
            await creditosApi.editar(this.persona_id, this.credito.id, payload)
            this.$helper.showMessage('Editar credito', 'Información del credito guardada exitosamente.', 'success', 'alert')

          } else {
            let request = await creditosApi.crear(this.persona_id,this.credito.id, payload)
            this.credito = {no_credito: request.data.external_id, ...request.data}

            this.$helper.showMessage('Crear credito', 'Información del credito guardada exitosamente.', 'success', 'alert')
            await this.cargarCampos()
          }

          this.$emit('actualizar', this.credito)

        }catch(e){
            this.$log.error('Error', e);
            if (e.response && e.response.status !== 400)
              this.$helper.showMessage((!this.credito.id ? 'Guardar' : 'Editar') + ' credito', 'Ocurrio un error al guardar datos del credito, favor de reportarlo al administador.', 'error', 'alert')
          }
        }
      }

      ,async cargarCampos(){

      let dataSource = await creditosApi.campos_dinamicos()
      this.campos_dinamicos = dataSource.data

      for (const campoKey in this.campos_dinamicos) {

        const campo = this.campos_dinamicos[campoKey]

        /**
         * Si no esta editando un crédito se descartan los campos dinámicos que no esten activos para
         * el nuevo registro
         */
        if (!this.credito_id){
          if (!campo.activo) {
            this.campos_dinamicos.splice(campoKey,1)
          }else
            this.credito[campo.clave] = null

          continue
        }

        let propiedad = {}

        /**
         * Validar si se esta editando un registro y buscar en sus propiedades los valores de los campos dinamicos
         * las propiedades se obtienen al consultar el registro en el api y se asigna sus valores
         * */
        if(this.credito.propiedades){
          propiedad = this.credito.propiedades.find(propiedad => propiedad.campo_id === campo.id)


          /**
           * Se omiten los campos dinámicos que esten inactivos si la propiedad para el mismo no existe en
           * las propiedades del registro en edición
           */
          if (!campo.activo && !propiedad) {
            this.campos_dinamicos.splice(campoKey,1)
            continue
          }
        }

        this.credito[campo.clave] = propiedad && propiedad.hasOwnProperty('valor')  ? propiedad.valor : null
      }
    }
    /**
     * Valida si hay un origen de datos para el formulario y habilitar botón para cargar origen de datos
     * Para que la asignación de la propiedad es_de_origen_externo sea correcta para los campos dinámicos
     * primero se debieron haber cargado los campos dinámicos del formulario.
     * @returns {Promise<void>}
     */
    , async validar_origen() {

      const request = await origenDatosApi.get_origen('formulario_credito')
      if (request.data[0] && request.data[0].origen_asincrono) {
        // Hablilita botón para ingresar datos necesarios para cargar origen de datos
        this.configuracion_origen_datos =  request.data[0]
      }

      //Validar que campos van a tener el ícono de origen externo en el formulario tanto para los campos dinámicos
      if (this.configuracion_origen_datos.campos){
        for (const campo_origen of this.configuracion_origen_datos.campos) {
          //Se asigna la propiedad es_de_origen_externo a los campos dinámicos que esten en la configuración de campos de origen externo
          const index_campo_dinamico = this.campos_dinamicos.findIndex( campo_dinamico => campo_dinamico.id = campo_origen.campo_id)
          if (index_campo_dinamico >= 0){
            this.campos_dinamicos[index_campo_dinamico].es_de_origen_externo = true
          }
        }
      }

    }

    ,pre_cargar_origen() {
      if (this.configuracion_origen_datos.parametros_request.length > 0)
        this.form_parametros_cargar_origen_datos = true
      else
        this.cargar_origen()
    }

    ,form_origen_enviar(event){
      this.form_parametros_cargar_origen_datos = false
      this.cargar_origen(event)
    }

    , async cargar_origen(request_params = {}) {

      const dataSource = await origenDatosApi.consultar_origen('formulario_credito',request_params)
      this.origen_datos_response = dataSource.data

      if (this.origen_datos_response.length && this.origen_datos_response.length > 0)
        this.form_seleccion_origen_de_datos = true
      else
          //Asignar los valores del unico resultado obtenido
        this.asignar_valores_origen(this.origen_datos_response[0]);

    },

    asignar_valores_origen(data){

      this.municipio_response_origen_datos = null
      this.form_seleccion_origen_de_datos = false
      const campos_dinamicos_valores = !data.campos_dinamicos ? [] : data.campos_dinamicos

      //Asignar valores a propiedades de garantía
      for (const [key, value] of Object.entries(data.valores_origen)) {

        if (this.credito.hasOwnProperty(key)) {
            this.credito[key] = value
        }
      }
      //Asignar valores de campos dinámicos
      for (const campo of campos_dinamicos_valores) {
        if (this.fideicomiso.hasOwnProperty(campo.clave)) {
          let valor = campo.valor_origen ? campo.valor_origen : null;

          if (campo.tipo_dato === 'date' && !!valor) {
            valor = this.$moment(valor).format("YYYY-MM-DD")
          }

          this.credito[campo.clave] = valor
        }
      }
    }
    ,cancelar_form_seleccion_origen(){
      this.form_seleccion_origen_de_datos= false
    }
    ,cancelar_form_params_origen(){
      this.form_parametros_cargar_origen_datos = false
    }
    /**
     * Confirma cambio de estaus a uno de solo lectura, si el valor de la confirmación es falso
     * @param confirmacion
     */
    ,confirmar_cambio_estatus_lectura(confirmacion){
      if (!confirmacion)
        this.credito.estatus = this.estatus_credito_db
      else {
        this.guardar();
      }

      this.modalConfirmarCambioEstatusLectura = false
    }
  }
  ,watch: {
    'credito.estatus': {
      handler(newValue, oldValue) {
        //Si se edita el estaus de un crédito ya guardado por un estatus de solo lectura abre modal para confirmar cambio
        if (oldValue !== null && this.cambio_estatus_a_inactivo) {
          this.modalConfirmarCambioEstatusLectura = true
        }
      }
    }
  }

}
</script>

<style scoped lang="scss">

</style>
