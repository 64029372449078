import garantiasAdminApi from "../garantias_admin.api";
import utils from "@/helpers/utilidades";

export default {

  index(paginado = true, options = {}) {
    return garantiasAdminApi.api.get(utils.url_options(`${garantiasAdminApi.baseURL}/fondeadores`, options, paginado));
  }

  ,obtener(persona) {
    return garantiasAdminApi.api.get(`${garantiasAdminApi.baseURL}/fondeadores/${persona}`);
  },

  crear(payload) {
    return garantiasAdminApi.api.post(`${garantiasAdminApi.baseURL}/fondeadores`, payload)
  },

  editar(persona, payload) {
    return garantiasAdminApi.api.put(`${garantiasAdminApi.baseURL}/fondeadores/${persona}`, payload)
  },

  eliminar(persona) {
    return garantiasAdminApi.api.delete(`${garantiasAdminApi.baseURL}/fondeadores/${persona}`)
  },

}